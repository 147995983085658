<template>
  <base-material-card inline class="mb-0" icon="mdi-database-import" :title="$t('Import CSV')">
    <v-row dense>
      <v-col class="text-h4 font-weight-bold mt-2" cols="4" sm="4">
        {{ $t("Selectați tabela:") }}
      </v-col>
      <v-col cols="8" sm="8">
        <v-select v-model="domain" style="width:300px" dense :items="domains" @change="changeDomain">
          <template v-slot:item="props">
            <span v-if="props.item.root" class=" font-weight-bold">{{ props.item.text }}</span>
            <span v-else>{{ props.item.text }}</span>
          </template>
          <template v-slot:selection="{ item }">
            <span class="text-subtitle-2">{{ item.text }}</span>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row dense>
      <!-- <v-col cols="12" sm="6">
        <v-select v-model="encoderCSV" style="width:200px" dense :label="$t('Format caractere')" :items="['utf-8']" />
      </v-col> -->
      <v-col cols="12" sm="6">
        <v-select v-model="separatorCSV" style="width:200px" dense :label="$t('Separator câmp')" :items="separators" />
      </v-col>
      <v-col cols="12" sm="6">
        <v-select
          v-model="endlineCSV"
          style="width:200px"
          dense
          :label="$t('Separator înregistrare')"
          :items="endlineValues"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-select
          v-model="encloserCSV"
          style="width:200px"
          dense
          :label="$t('Delimitator câmp')"
          :items="encloserValues"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-checkbox v-model="headerCSV" dense class="mt-2" :label="$t('Cap de tabel')" />
      </v-col>
      <v-col cols="12" class="d-flex">
        <v-text-field
          v-model="name"
          :label="$t('Fișier')"
          :rules="inputValidate"
          class="pt-0"
          clearable
          readonly
          @click="$refs.file.click()"
          @click:clear="
            name = ''
            file = null
          "
        >
          <template v-slot:prepend>
            <v-icon @click="$refs.file.click()">
              mdi-paperclip
            </v-icon>
          </template>
        </v-text-field>
        <span v-show="csvValues.length > 0" class="ml-2 mt-2">
          {{ csvValues.length }}&nbsp;
          {{ $t("Înregistrări") }}
        </span>
      </v-col>
      <v-col v-show="file" cols="12">
        <v-data-table
          ref="table"
          dense
          fixed-header
          :server-items-length="2000"
          :headers="headers"
          :items="records"
          hide-default-footer
        >
          <template v-slot:item.field="props">
            <v-autocomplete v-model="props.item.field" dense hide-details clearable :items="filtersFields" />
          </template>
          <template v-slot:item.default="props">
            <v-text-field v-model="props.item.default" dense hide-details />
          </template>
        </v-data-table>
      </v-col>
      <v-col v-show="file" cols="12" class="d-flex">
        <div style="width:200px">
          <v-text-field v-model="startIndex" class="mr-3" :label="$t('Început')" />
        </div>
        <div style="width:200px">
          <v-text-field v-model="endIndex" class="mr-3" :label="$t('Sfârșit')" />
        </div>
        <v-spacer />
        <v-btn v-show="!importing" color="primary" class="mt-3 mr-2" :disabled="!file" @click="importCSV">
          {{ $t("Import") }}
        </v-btn>
        <v-btn v-show="importing" color="error" class="mt-3 mr-2" :disabled="!file" @click="importing = false">
          {{ $t("Oprește") }}
        </v-btn>
      </v-col>
    </v-row>
    <div v-show="false">
      <input
        ref="file"
        type="file"
        accept=".csv,.txt"
        @change="
          file = $event.target.files[0]
          name = $event.target.files[0].name
          contentType = $event.target.files[0].type
          parse()
        "
      />
      <!-- accept="image/*" -->
      <!-- createURL('urlImg', $event) -->
    </div>
  </base-material-card>
</template>
<script>
import axios from "../../plugins/axios"
// import { EventBus } from "../../EventBus"
export default {
  components: {},
  data: () => ({
    filtersFields: [],
    fields: [],
    fieldSchema: {},
    fieldTypes: {},
    records: [],
    domains: [],
    csvValues: [],
    importing: false,
    startIndex: 1,
    endIndex: 1,
    currentIndex: 0,
    domain: "cln",
    file: null,
    name: "",
    contentType: "",
    collection: "",
    nameCSV: "export",
    headerCSV: true,
    separatorCSV: "",
    separators: [
      {
        text: "auto",
        value: ""
      },
      {
        text: "\\t",
        value: "\t"
      },
      {
        text: ",",
        value: ","
      },
      {
        text: ";",
        value: ";"
      },
      {
        text: "#",
        value: "#"
      }
    ],
    encloserCSV: '"',
    encoderCSV: "utf-8",
    encloserValues: ['"', "'", ""],
    endlineCSV: "",
    endlineValues: [
      {
        text: "auto",
        value: ""
      },
      {
        text: "\\r\\n",
        value: "\r\n"
      },
      {
        text: "\\n",
        value: "\n"
      },
      {
        text: "\\r",
        value: "\r"
      },
      {
        text: "#",
        value: "#"
      }
    ],
    headers: [
      { text: "Cap de tabel", value: "head", align: "left", type: "String", width: "25%" },
      { text: "Valori", value: "value", align: "left", type: "String", width: "25%" },
      { text: "Camp eBibliophil", value: "field", align: "left", type: "String", width: "25%" },
      { text: "Valoare predefinita", value: "default", align: "left", type: "String", width: "25%" }
    ]
  }),
  computed: {
    appSchema() {
      return this.$store.getters.appSchema
    },
    inputValidate() {
      const rule = []
      rule.push(v => !!v || this.$t("required"))
      return rule
    },
    companyId() {
      return this.$store.getters.companyId
    }
  },
  created() {
    //this.domains.push({ text: "Cititori", value: "cln", root: true })
    this.addDomainsCat("cln", "Cititori")
    //this.domains.push({ text: "Monografii", value: "mon", root: true })
    this.addDomainsCat("mon", "Monografii")
    //this.domains.push({ text: "Articole", value: "art", root: true })
    this.addDomainsCat("art", "Articole")
    //this.domains.push({ text: "Autorități", value: "ath", root: true })
    this.addDomainsCat("ath", "Autorități")
    this.changeDomain("cln.reader")
  },
  methods: {
    importCSV() {
      this.$log("import ", this.records)
      this.currentIndex = this.startIndex - 1
      this.importing = true
      this.importNext()
    },
    importNext() {
      if (this.currentIndex < this.endIndex && this.importing) {
        const ctg = this.domain.split(".")[1]
        const itm = this.csvValues[this.currentIndex]
        this.currentIndex++
        this.startIndex = this.currentIndex
        // this.csvValues.slice(this.startIndex - 1, this.endIndex).map(itm => {
        const rec = { fields: {}, ctg: ctg, company: this.companyId }
        this.records.map(flds => {
          if (flds.field && (itm[flds.head] || flds.default)) {
            //this.$log("type ", this.fieldTypes[flds.field])
            const arr = flds.field.split(".")
            if (arr[0] == "fields") {
              if (rec.fields[arr[1]]) {
                if (itm[flds.head]) {
                  if (this.fieldTypes[flds.field] == "datetime" && itm[flds.head].length < 12) {
                    rec.fields[arr[1]][0][arr[2]] = [{ val: itm[flds.head] + " 00:00:00" }]
                  } else {
                    rec.fields[arr[1]][0][arr[2]] = [{ val: itm[flds.head] }]
                  }
                } else {
                  rec.fields[arr[1]][0][arr[2]] = [{ val: flds.default }]
                }
              } else {
                const sub = {}
                if (itm[flds.head]) {
                  if (this.fieldTypes[flds.field] == "datetime" && itm[flds.head].length < 12) {
                    sub[arr[2]] = [{ val: itm[flds.head] + " 00:00:00" }]
                  } else {
                    sub[arr[2]] = [{ val: itm[flds.head] }]
                  }
                } else {
                  sub[arr[2]] = [{ val: flds.default }]
                }
                rec.fields[arr[1]] = [sub]
                //rec.fields[arr[1]].push(sub)
              }
            } else if (arr[0] == "ctg") {
              rec.ctg = itm.ctg
            }
          }
        })
        //this.$log("rec ", rec)
        axios
          .post("ebib_" + this.collection, rec)
          .then(response => {
            this.importNext()
            // if (response.status == 201) {
            //   this.$log("added ", response)
            // }
          })
          .catch(() => this.importNext())
      } else {
        this.importing = false
      }
      // })
    },
    parse() {
      this.startIndex = 1
      const config = {
        header: this.headerCSV,
        quoteChar: this.encloserCSV,
        skipEmptyLines: true,
        complete: this.parseComplete
      }
      if (this.endlineCSV) {
        config.newLine = this.endlineCSV
      }
      if (this.separatorCSV) {
        config.delimiter = this.separatorCSV
      }
      this.$papa.parse(this.file, config)
    },
    parseComplete(e) {
      this.$log("parseC ", e)
      this.csvValues = e.data
      this.endIndex = this.csvValues.length
      this.records = []
      if (e.meta.fields) {
        e.meta.fields.map((head, ind) => {
          if (this.filtersFields.find(itm => itm.text.toLowerCase().includes(head.toLowerCase()))) {
            this.records.push({
              head: head,
              value: e.data[0][head],
              field: this.filtersFields.find(itm => itm.text.toLowerCase().includes(head.toLowerCase())).value,
              default: ""
            })
          } else {
            this.records.push({
              head: head,
              value: e.data[0][head],
              field: "",
              default: ""
            })
          }
        })
      } else {
        e.data[0].map((head, ind) => {
          this.records.push({ head: head, value: e.data[1][ind], field: "", default: "" })
        })
      }
    },
    addDomainsCat(prefix, lbl) {
      const cat = this.$store.getters.confPrefixes.filter(itm => itm.prefix == prefix && itm.category != "")
      cat.sort((a, b) => {
        return a.ordPref - b.ordPref
      })
      cat.map(itm => {
        this.domains.push({ text: lbl + ": " + itm.lblRo, value: itm.prefix + "." + itm.category })
      })
    },
    changeDomain(e, query) {
      this.$log("change dom ", e)
      this.collection = "marc_" + e.split(".")[0]
      this.domain = e
      this.filtersFields = []
      this.generateListFields(this.appSchema.filter(itm => itm.collection == this.collection)[0].content, "")
    },
    generateListFields(rec, name) {
      //this.$log("view type ", this.viewType)
      for (const prop in rec) {
        if ((prop === "type" || prop === "schema") && rec.type && rec.schema) {
          if (prop === "type") {
            this.generateListFields(rec.schema, name)
          }
        } else {
          let newname = ""
          if (name) {
            newname = name + "." + prop
          } else {
            newname = prop
          }
          if (rec[prop].schema) {
            let lbl = newname
            if (rec[prop].meta) {
              lbl = rec[prop].meta.label
            }
            if (rec[prop].type === "list" && rec[prop].schema.type != "dict") {
              this.filtersFields.push({ text: lbl, value: newname, type: rec[prop].type })
              this.fieldTypes[newname] = rec[prop].schema.type
              this.fieldSchema[newname] = rec[prop].schema
            } else if (rec[prop].type === "list") {
              // const flds = newname.split(".")
              // if (this.viewType == "marc" && flds[0] == "fields" && flds.length == 2) {
              //   this.filtersFields.push({ text: flds[1] + " - " + lbl, value: newname, type: rec[prop].type })
              //   this.fieldTypes[newname] = rec[prop].type
              //   this.fieldSchema[newname] = rec[prop]
              // } else if (this.viewType != "marc") {
              //   this.filtersFields.push({ text: lbl, value: newname, type: rec[prop].type })
              //   this.fieldTypes[newname] = rec[prop].type
              //   this.fieldSchema[newname] = rec[prop]
              // }
            }
            this.generateListFields(rec[prop].schema, newname)
          } else {
            if (rec[prop].type) {
              if (
                prop != "id" &&
                prop != "company" &&
                prop != "ind1" &&
                prop != "ind2" &&
                prop != "ord" &&
                prop != "link" &&
                prop != "src_id"
              ) {
                let lbl = newname
                if (rec[prop].meta) {
                  lbl = rec[prop].meta.label
                }
                //this.$log("viretype marc flds ", newname)
                const flds = newname.split(".")
                if (flds[2] && flds[0] == "fields" && flds[3] == "val") {
                  lbl = flds[1] + flds[2] + " - " + lbl
                } else if (flds[0] == "fields" && (flds[2] == "ind1" || flds[2] == "ind2")) {
                  lbl = flds[1] + " - " + lbl
                }
                this.filtersFields.push({ text: lbl, value: newname, type: rec[prop].type })
                this.fieldTypes[newname] = rec[prop].type
                this.fieldSchema[newname] = rec[prop]
                this.fieldSchema[newname].lbl = lbl
              }
            }
          }
        }
      }
    }
  }
}
</script>
<style scoped></style>
